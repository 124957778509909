const internet = [
  {
    id: 'internet-start',
    title: "Start Xtra",
    downloadSpeed: 400,
    uploadSpeed: 50,
    price: 5100,
    discountedPrice: 2250,
    discountedPricePeriodMeta: 12,
    items: [
      { title: "400 Mbit/s download" },
      { title: "50 Mbit/s upload" },
      { title: "Safe Online: 1 licentie" },
      { title: "Wifi Back-up Zakelijk" },
      { title: "Business Crew" },
      { title: "Unlimited Learning" },
      { title: "2 SmartWifi pods", /*notincluded: false*/ },
    ]
  },
  {
    id: 'internet-complete',
    title: "Complete Xtra",
    downloadSpeed: 800,
    uploadSpeed: 70,
    price: 4750,
    discountedPrice: 2625,
    discountedPricePeriodMeta: 12,
    items: [
      { title: "800 Mbit/s download" },
      { title: "70 Mbit/s upload" },
      { title: "Safe Online: 3 licenties" },
      { title: "Wifi Back-up Zakelijk" },
      { title: "Business Crew" },
      { title: "Unlimited Learning" },
      { title: "2 SmartWifi pods" },
    ]
  },
  {
    id: 'internet-giga',
    title: "Premium Xtra",
    downloadSpeed: 1200,
    uploadSpeed: 120,
    price: 6000,
    discountedPrice: 3250,
    discountedPricePeriodMeta: 12,
    items: [
      { title: "1200 Mbit/s download" },
      { title: "120 Mbit/s upload" },
      { title: "Safe Online: 5 licenties" },
      { title: "Wifi Back-up Zakelijk" },
      { title: "Business Crew" },
      { title: "Unlimited Learning" },
      { title: "2 SmartWifi pods" },
    ]
  },
];

const tv = [
  {
    id: 'tv-start',
    title: "TV Start Next",
    price: 550,
    digitalItems: [
      { title: "70+ Kanalen" },
      { title: "Ziggo sport" },
      { title: "EUFA Europees voetbal" },
      { title: "Terugkijken: 2 dagen" },
      { title: "Opnemen", notincluded: true },
      { title: " Movies & Series", notincluded: true },
      { title: " SkyShowtime", notincluded: true },
    ],
  },
  {
    id: 'tv-complete',
    title: "TV Complete Next",
    price: 1500,
    digitalItems: [
      { title: "70+ Kanalen" },
      { title: "Ziggo sport" },
      { title: "EUFA Europees voetbal" },
      { title: "Terugkijken: 2 dagen" },
      { title: "Opnemen", notincluded: false },
      { title: " Movies & Series", notincluded: false },
      { title: " SkyShowtime", notincluded: true },
    ],
  },
  {
    id: 'tv-kabel',
    title: "Kabel TV",
    price: 0,
    digitalItems: [
      { title: "50+ Kanalen", info: "<div>Overal tv met ZIGGO GO<div>" },
      { title: "Alleen tv-signaal (geen Mediabox)" },
      { title: "Inc. Ziggo Sport" },
      { title: "Overal tv met Ziggo GO" },
    ]
  },
];

const phone = [
  {
    id: 'phone-vast',
    title: "Zakelijk vast bellen",
    price: 250,
    items: [
      { title: "12 cent per minuut" },
      { title: "Nummerbehoud" },
    ]
  },
  {
    id: 'phone-geen',
    title: "Geen telefonie",
    price: 0,
  }
];

const extra = [
  {
    id: 'gratisbellen',
    title: "Onbeperkt Bellen Nationaal",
    price: 1500,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
  {
    id: 'sport',
    title: "Ziggo Sport Totaal",
    price: 1236,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
  {
    id: 'ESPN compleet',
    title: "ESPN compleet",
    price: 1483,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
  {
    id: 'movies',
    title: "Movies & Series XL",
    price: 988,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
];

export {
  internet as internetProducts,
  tv as tvProducts,
  phone as phoneProducts,
  extra as extraProducts,
}
